import { PlainMessage } from "@bufbuild/protobuf";
import { Money } from "@egocentric-systems/ts-apis/types/v1/money_pb";
import { z } from "zod";
import { useTranslations } from "~/hooks/useTranslations";
import { moneyToCurrency, moneyToNumber } from "~/lib/utils";

interface Options {
  min: PlainMessage<Money>;
  max: PlainMessage<Money>;
}

export function useGiftcardSchema({ min, max }: Options) {
  const t = useTranslations();

  const minNumber = moneyToNumber(min);
  const maxNumber = moneyToNumber(max);

  return z.object({
    balance: z
      .object(
        {
          major: z.number(),
          minor: z.number(),
          currency: z.string(),
        },
        {
          required_error: t.giftcards.errors.empty,
          invalid_type_error: t.giftcards.errors.empty,
        },
      )
      .superRefine((balance, ctx) => {
        const balanceNumber = moneyToNumber(balance);

        if (balanceNumber < minNumber) {
          ctx.addIssue({
            message: t.giftcards.errors.minimum.replace(
              "{money}",
              moneyToCurrency(min),
            ),
            code: "custom",
          });
        }

        if (maxNumber < balanceNumber) {
          ctx.addIssue({
            message: t.giftcards.errors.maximum.replace(
              "{money}",
              moneyToCurrency(max),
            ),
            code: "custom",
          });
        }
      }),
  });
}

export type GiftcardSchema = z.infer<ReturnType<typeof useGiftcardSchema>>;
