import(/* webpackMode: "eager" */ "/vercel/path0/app/global.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/apple-wallet.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/google.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/klarna.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/mastercard.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/paypal.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/paymentMethods/visa.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/assets/ventiq_Logo_Design_V1.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/CartCheckInterval.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/CartTimer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Cookies"] */ "/vercel/path0/components/Cookies/Cookies.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DtmAdventSpecial"] */ "/vercel/path0/components/dtm/DtmAdventSpecial.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/GoogleTagManager.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Footer/components/CookieSettings.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/CartAmount.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CartLink"] */ "/vercel/path0/components/navigation/Header/components/CartLink.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/Languages.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/NavigationSearch.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/Script.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/navigation/Header/components/User.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/payment/PayPalWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ShopConfig"] */ "/vercel/path0/components/ShopConfig.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeWrapper"] */ "/vercel/path0/components/ThemeWrapper/ThemeWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/components/utils/Analytics.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Anton\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-anton\",\"weight\":[\"400\"]}],\"variableName\":\"fontAnton\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Libre_Barcode_39\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-barcode\",\"weight\":[\"400\"]}],\"variableName\":\"fontBarcode\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Alfa_Slab_One\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-alfa-slab\",\"weight\":[\"400\"]}],\"variableName\":\"fontAlfaSlab\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":[\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"fontInter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Archivo_Black\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-archivo\",\"weight\":[\"400\"]}],\"variableName\":\"fontArchivo\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"app/univia.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"./fonts/univia-pro/UniviaPro-Thin.otf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-ThinItalic.otf\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-UltraLight.otf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-UltraLightItalic.otf\",\"weight\":\"200\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-LightItalic.otf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Italic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-MediumItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-BoldItalic.otf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Ultra.otf\",\"weight\":\"800\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-UltraItalic.otf\",\"weight\":\"800\",\"style\":\"italic\"},{\"path\":\"./fonts/univia-pro/UniviaPro-Black.otf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"./fonts/univia-pro/UniviaPro-BlackItalic.otf\",\"weight\":\"900\",\"style\":\"italic\"}],\"variable\":\"--font-univia\"}],\"variableName\":\"fontUnivia\"}");
