"use client";

import { LucideEuro } from "lucide-react";
import { cn, numberToMoney } from "~/lib/utils";
import { buttonVariants } from "../ui/button";
import { Input } from "../ui/input";
import { useTranslations } from "~/hooks/useTranslations";
import { useCart } from "~/hooks/useCart";
import { useState } from "react";
import { useLocale } from "~/hooks/useLocale";
import { Controller, useFormContext } from "react-hook-form";
import { GiftcardSchema } from "./useGiftcardSchema";
import { ValidationError } from "../ValidationError";

interface Props {
  type: "card" | "banner";
}

export function GiftcardBalanceInput({ type }: Props) {
  const t = useTranslations();

  const form = useFormContext<GiftcardSchema>();

  const locale = useLocale();
  const { currency } = useCart();

  const [rawValue, setRawValue] = useState("");

  return (
    <label
      className={cn(
        buttonVariants({ variant: "dark" }),
        "min-w-64 justify-between border-0 p-0 md:w-max",
      )}
    >
      <div className="w-full grow">
        <Controller
          control={form.control}
          name="balance"
          render={({
            field: { onChange, onBlur, name, ref, disabled },
            fieldState: { error },
          }) => {
            return (
              <div className="flex flex-col gap-1">
                <Input
                  value={rawValue}
                  onChange={(e) => {
                    setRawValue(e.target.value);
                  }}
                  name={name}
                  onBlur={(e) => {
                    const value =
                      e.target.value &&
                      Number(e.target.value.replace(",", "."));

                    if (typeof value === "number" && !Number.isNaN(value)) {
                      const moenyValue = numberToMoney(value, currency);

                      onChange(moenyValue);
                      setRawValue(
                        value
                          .toLocaleString(locale, {
                            style: "currency",
                            currency,
                            currencyDisplay: "code",
                          })
                          .replace(currency, "")
                          .trim(),
                      );
                    } else {
                      onChange(null);
                      setRawValue("");
                    }

                    onBlur();
                  }}
                  className={cn(
                    buttonVariants({ variant: "dark" }),
                    "border-0 placeholder:text-gray-300",
                  )}
                  type="number"
                  placeholder={t.giftcards.input_balance}
                  inputRef={ref}
                  disabled={disabled}
                />

                <ValidationError
                  display={!!error}
                  message={error?.message}
                  className={cn(type === "banner" && "text-rose-300")}
                />
              </div>
            );
          }}
        />
      </div>

      <span className="px-2">
        <LucideEuro />
      </span>
    </label>
  );
}
