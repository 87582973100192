"use client";

import { faCartShopping, faGiftCard } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Image from "next/image";
import { useTranslations } from "~/hooks/useTranslations";
import { Button } from "../ui/button";
import { useCart } from "~/hooks/useCart";
import { cn, getLargestVariant } from "~/lib/utils";
import { GiftcardOptions } from "@egocentric-systems/ts-apis/booking_gateway/v1/shop_config_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import { GiftcardBalanceSelect } from "./GiftcardBalanceSelect";
import { GiftcardBalanceInput } from "./GiftcardBalanceInput";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { GiftcardSchema, useGiftcardSchema } from "./useGiftcardSchema";

const SYSTEM_MIN_BALANCE = {
  major: 5,
  minor: 0,
};

const SYSTEM_MAX_BALANCE = {
  major: 9999,
  minor: 99,
};

interface Props {
  giftcardOptions: PlainMessage<GiftcardOptions>;
  type?: "card" | "banner";
  textColor?: string;
}

export function GiftcardBanner({
  giftcardOptions,
  type = "card",
  textColor,
}: Props) {
  const t = useTranslations();

  const { addGiftcard, currency } = useCart();

  const form = useForm<GiftcardSchema>({
    resolver: zodResolver(
      useGiftcardSchema({
        min: giftcardOptions.minBalance ?? { ...SYSTEM_MIN_BALANCE, currency },
        max: giftcardOptions.maxBalance ?? { ...SYSTEM_MAX_BALANCE, currency },
      }),
    ),
  });

  const hasBalanceSuggestions = giftcardOptions.balanceSuggestions.length !== 0;

  const image = getLargestVariant(giftcardOptions.salesImage);

  return (
    <div
      className={cn(
        "grid grid-cols-1 justify-start gap-4 rounded p-4 md:grid-cols-[auto_minmax(0,_1fr)]",
        type === "card" && "bg-white",
      )}
    >
      <div className="flex justify-center">
        <div
          className={cn(
            "h-32 w-64 overflow-hidden rounded border border-gray-300",
            type === "banner" && "border-0",
          )}
        >
          {image && (
            <Image
              src={image.url}
              alt="giftcard preview"
              width={image.width}
              height={image.height}
            />
          )}
        </div>
      </div>

      <div className="flex w-full flex-col justify-between gap-2 py-2">
        <div
          className={cn(
            "flex items-center gap-2 text-lg",
            type === "banner" && "text-xl",
          )}
          style={{ color: textColor }}
        >
          <FontAwesomeIcon icon={faGiftCard} className="size-8" />
          <span className="font-anton">{t.giftcards.title}</span>
        </div>

        <div className="grid grid-cols-1 items-center justify-between gap-2 md:grid-cols-[minmax(0,_1fr)_auto]">
          <FormProvider {...form}>
            {hasBalanceSuggestions ? (
              <GiftcardBalanceSelect
                balanceSuggestions={giftcardOptions.balanceSuggestions}
                type={type}
              />
            ) : (
              <GiftcardBalanceInput type={type} />
            )}
          </FormProvider>

          <Button
            variant="outline"
            className="transition-transform active:scale-95"
            onClick={form.handleSubmit(({ balance }) => {
              addGiftcard({
                id: crypto.randomUUID(),
                balance,
              });
            })}
          >
            <FontAwesomeIcon icon={faCartShopping} />

            <span>{t.giftcards.add_to_cart}</span>
          </Button>
        </div>
      </div>
    </div>
  );
}
