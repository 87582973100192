import { motion, AnimatePresence } from "framer-motion";
import { cn } from "~/lib/utils";

interface IProps {
  message?: string;
  display?: boolean;
  className?: string;
}

export function ValidationError({
  message,
  display = true,
  className,
}: IProps): JSX.Element {
  return (
    <AnimatePresence>
      {display && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          className={cn(
            "mt-1 overflow-hidden text-sm leading-sm text-red",
            className,
          )}
        >
          {message}&nbsp;
        </motion.div>
      )}
    </AnimatePresence>
  );
}
