"use client";

import { cn, moneyToCurrency } from "~/lib/utils";
import { Select, SelectContent, SelectItem, SelectTrigger } from "../ui/select";
import { buttonVariants } from "../ui/button";
import { useTranslations } from "~/hooks/useTranslations";
import { PlainMessage } from "@bufbuild/protobuf";
import { Money } from "@egocentric-systems/ts-apis/types/v1/money_pb";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { GiftcardSchema } from "./useGiftcardSchema";
import { ValidationError } from "../ValidationError";

interface Props {
  balanceSuggestions: Array<PlainMessage<Money>>;
  type: "card" | "banner";
}

export function GiftcardBalanceSelect({ balanceSuggestions, type }: Props) {
  const t = useTranslations();

  const form = useFormContext<GiftcardSchema>();

  useEffect(() => {
    if (balanceSuggestions.length === 1) {
      form.setValue("balance", balanceSuggestions[0]);
    }
  }, [balanceSuggestions]);

  return (
    <Controller
      control={form.control}
      name="balance"
      render={({
        field: { value, onChange, onBlur, name, ref, disabled },
        fieldState: { error },
      }) => {
        const selectedBalanceIndex = balanceSuggestions.findIndex(
          (balanceSuggestion) =>
            balanceSuggestion.major === value?.major &&
            balanceSuggestion.minor === value.minor &&
            balanceSuggestion.currency === value.currency,
        );

        return (
          <div className="flex flex-col gap-1">
            <Select
              value={String(selectedBalanceIndex)}
              onValueChange={(selectedOption) => {
                const index = Number(selectedOption);

                onChange(balanceSuggestions[index]);
              }}
              disabled={disabled}
              name={name}
            >
              <SelectTrigger
                className={cn(
                  buttonVariants({ variant: "dark" }),
                  "min-w-64 justify-between border-0 md:w-max",
                )}
                ref={ref}
                onBlur={onBlur}
              >
                <span>
                  {value ? moneyToCurrency(value) : t.giftcards.choose_balance}
                </span>
              </SelectTrigger>
              <SelectContent>
                {balanceSuggestions.map((balanceSuggestion, i) => (
                  <SelectItem key={crypto.randomUUID()} value={String(i)}>
                    {moneyToCurrency(balanceSuggestion)}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>

            <ValidationError
              display={!!error}
              message={error?.message}
              className={cn(type === "banner" && "text-rose-300")}
            />
          </div>
        );
      }}
    />
  );
}
